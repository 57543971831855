import React from 'react';
import _ from 'lodash';

import {htmlToReact, withPrefix} from '../utils';
import ActionLink from './ActionLink';

export default class SectionCta extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        return (
            <section 
              id={_.get(section, 'section_id', null)}
              className="block cta-block bg-accent outer"
              style={{paddingTop: '8rem', paddingBottom: '8rem'}}
            >
              <div className="inner-large" style={{ maxWidth: '965px' }}>
                <div className="grid">
                  <div
                    className="cell block-content"
                    
                  >
                    {_.get(section, 'title', null) && (
                    <h2 className="block-title" style={{'fontSize': '1.9rem'}}>{_.get(section, 'title', null)}</h2>
                    )}
                    {_.get(section, 'subtitle', null) && (
                    <p className="block-subtitle">
                      {htmlToReact(_.get(section, 'subtitle', null))}
                    </p>
                    )}
                  </div>
                  {_.get(section, 'actions', null) && (<div className="cell block-buttons">
                  <div className="">
                    {_.map(_.get(section, 'actions', null), (action, action_idx) => (
                      <ActionLink key={action_idx} {...this.props} action={action} class_names={'button white large'} />
                    ))}
                  </div>
                    <div style={{display: 'inline-block'}}>
                      {_.get(section, 'logo1', null) && (
                        <img className="review-avatar" src={withPrefix(_.get(section, 'logo1', null))} alt="Barrie Transit"/>
                      )}
                      {_.get(section, 'logo3', null) && (
                        <img style={{maxWidth: '300px'}} className="review-avatar" src={withPrefix(_.get(section, 'logo3', null))} alt="Brampton Transit"/>
                      )}
                    </div>
                    <div hidden={true}>
                      {_.get(section, 'logo3', null) && (
                        <img className="review-avatar" src={withPrefix(_.get(section, 'logo3', null))} alt="Brampton Transit"/>
                      )}
                    </div>
                  </div>)}
                </div>
              </div>
            </section>
        );
    }
}
