import React from 'react';
import _ from 'lodash';

import {withPrefix, markdownify} from '../utils';
import ActionLink from './ActionLink';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

export default class SectionHero extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        return (
            <section id={_.get(section, 'section_id', null)} 
              className="block hero-block bg-accent outer"
              style={{paddingTop: '4rem', paddingBottom: '4.5rem'}}
            >
            <ParallaxProvider>
              <div className="inner">
                <div className="grid">
                  {_.get(section, 'rightcontent', null) && (
                  <div className="cell block-content">
                    {markdownify(_.get(section, 'rightcontent', null))}
                  </div>
                  )}
                  <div className="cell block-content">
                    <Parallax speed={-5}>
                      {_.get(section, 'title', null) && _.get(section, 'title', null).split('<br>').map(titleSegment => (
                      <h2 className="block-title underline" style={{fontSize: '34px'}}>{titleSegment}</h2>
                      ))}
                      <div className="block-title" style={{
                        textAlign: (_.get(section, 'alignContentLeft') ? 'left' : 'center'),
                        fontSize: (_.get(section, 'alignContentLeft') ? '1.1rem' : '1.69rem')
                      }}>
                        {markdownify(_.get(section, 'content', null))}
                      </div>
                      {_.get(section, 'actions', null) && (
                      <p className="block-buttons">
                        {_.map(_.get(section, 'actions', null), (action, action_idx) => (
                          <ActionLink key={action_idx} {...this.props} action={action} class_names={'button white large'} />
                        ))}
                      </p>
                      )}
                    </Parallax>
                  </div>
                </div>
              </div>
              </ParallaxProvider>
            </section>
        );
    }
}
